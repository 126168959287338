<template>
  <b-container
    id="privacy-policy"
    class="my-4"
  >
    <b-row>
      <b-col>
        <h2>Privacy Policy</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>

        <div class="content-sec-banner">
          <h1><span
            id="PRIVACY_POLICY"
            class="ez-toc-section _mPS2id-t mPS2id-target mPS2id-target-first"
          />PRIVACY POLICY<span class="ez-toc-section-end" /></h1>
          <h2><span
            id="SUMMARY"
            class="ez-toc-section _mPS2id-t mPS2id-target mPS2id-target-last"
          />SUMMARY<span class="ez-toc-section-end" /></h2>
          <p>We collect information that you provide to us or voluntarily share with other users, in addition to general technical information that is automatically collected by our systems, including IP addresses, browser information and cookies, to provide a better user experience and generally improve the Service. Your personal information may be gathered from subscribers of the Service, websites you use that we operate, and third parties. Technical information about how you access and use the service, as well as information about your device, is automatically collected and used for Service operation, optimization, analytics, content promotion and enhancement of user experience. In accordance with applicable local law, we may use your information to contact you with relevant information about the Service and our other products and services. We do not disclose your personal information to others except as described in this Policy or in connection with the Service. Information that you choose to publish on our Website or social media accounts (blogs, testimonials, videos, text, music, reviews, deliveries) may be read, collected and used by others who access them. All of the information that you provide to us is stored on secure servers in a controlled environment with limited access. The personal information we collect may be transferred to and stored in countries outside of the jurisdiction you are in. These international transfers are made pursuant to appropriate safeguards to protect your personal information. You may exercise your rights in respect of your personal information that we hold by contacting us at [PRIVACY EMAIL]. Additional information regarding the rights applicable to you are in the detailed terms of the Policy below. The above-noted summary contains highlights of our policy. We encourage you to read more about the information we collect and how we use it in the detailed terms of our Policy below. </p><div
            id="ez-toc-container"
            class="ez-toc-v2_0_36_1 counter-hierarchy ez-toc-counter ez-toc-light-blue ez-toc-container-direction"
          >
            <p class="ez-toc-title">
              Table of Contents
            </p>
            <nav><ul class="ez-toc-list ez-toc-list-level-1">
              <li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-1 __mPS2id _mPS2id-h mPS2id-highlight mPS2id-highlight-first"
                  href="#PRIVACY_POLICY"
                  title="PRIVACY POLICY"
                >PRIVACY POLICY</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-2 __mPS2id _mPS2id-h mPS2id-highlight mPS2id-highlight-last"
                      href="#SUMMARY"
                      title="SUMMARY"
                    >SUMMARY</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-3 __mPS2id _mPS2id-h"
                      href="#DETAILED_TERMS"
                      title="DETAILED TERMS"
                    >DETAILED TERMS</a><ul class="ez-toc-list-level-3">
                      <li class="ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-4 __mPS2id _mPS2id-h"
                          href="#PERSONAL_INFORMATION_WE_COLLECT_ABOUT_YOU_AND_HOW_WE_USE_IT"
                          title="PERSONAL INFORMATION WE COLLECT ABOUT YOU AND HOW WE USE IT"
                        >PERSONAL INFORMATION WE COLLECT ABOUT YOU AND HOW WE USE IT</a>
                      </li>
                    </ul>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-5 __mPS2id _mPS2id-h"
                      href="#The_following_table_Table_2_sets_out_the_categories_of_personal_information_we_collect_about_you_automatically_how_we_use_that_information_and_the_legal_basis_which_we_rely_upon_to_process_the_information_and_determine_applicable_retention_periods"
                      title="The following table (“Table 2”) sets out the categories of personal information we collect about you automatically, how we use that information, and the legal basis which we rely upon to process the information and determine applicable retention periods:&nbsp;"
                    >The following table (“Table 2”) sets out the categories of personal information we collect about you automatically, how we use that information, and the legal basis which we rely upon to process the information and determine applicable retention periods:&nbsp;</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-6 __mPS2id _mPS2id-h"
                      href="#We_may_link_or_combine_the_personal_information_we_collect_andor_receive_about_you_and_the_information_we_collect_automatically_This_allows_us_to_provide_you_with_a_personalized_experience_regardless_of_how_you_interact_with_us"
                      title="We may link or combine the personal information we collect and/or receive about you and the information we collect automatically. This allows us to provide you with a personalized experience regardless of how you interact with us."
                    >We may link or combine the personal information we collect and/or receive about you and the information we collect automatically. This allows us to provide you with a personalized experience regardless of how you interact with us.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-7 __mPS2id _mPS2id-h"
                      href="#We_may_anonymize_and_aggregate_any_of_the_personal_information_we_collect_so_that_it_does_not_identify_you_We_may_use_anonymized_information_for_purposes_that_include_testing_our_IT_systems_research_data_analysis_improving_the_Service_and_developing_new_products_and_features_We_may_also_share_such_anonymized_information_with_others"
                      title="We may anonymize and aggregate any of the personal information we collect (so that it does not identify you). We may use anonymized information for purposes that include testing our IT systems, research, data analysis, improving the Service and developing new products and features. We may also share such anonymized information with others."
                    >We may anonymize and aggregate any of the personal information we collect (so that it does not identify you). We may use anonymized information for purposes that include testing our IT systems, research, data analysis, improving the Service and developing new products and features. We may also share such anonymized information with others.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-8 __mPS2id _mPS2id-h"
                  href="#DISCLOSURE_OF_YOUR_PERSONAL_INFORMATION"
                  title="DISCLOSURE OF YOUR PERSONAL INFORMATION"
                >DISCLOSURE OF YOUR PERSONAL INFORMATION</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-9 __mPS2id _mPS2id-h"
                      href="#We_do_not_sell_or_rent_your_personal_information_to_third_parties_for_their_direct_marking_purposes_without_your_explicit_consent"
                      title="We do not sell or rent your personal information to third parties for their direct marking purposes without your explicit consent.&nbsp;"
                    >We do not sell or rent your personal information to third parties for their direct marking purposes without your explicit consent.&nbsp;</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-10 __mPS2id _mPS2id-h"
                      href="#We_may_share_your_personal_information_with_any_member_of_our_group_which_includes_our_subsidiaries_We_will_not_share_your_personal_information_with_any_third_parties_except_as_described_in_this_privacy_policy_or_in_connection_with_the_Service_We_may_share_your_information_with_selected_third_parties_including"
                      title="We may share your personal information with any member of our group, which includes our subsidiaries. We will not share your personal information with any third parties except as described in this privacy policy or in connection with the Service. We may share your information with selected third parties, including:"
                    >We may share your personal information with any member of our group, which includes our subsidiaries. We will not share your personal information with any third parties except as described in this privacy policy or in connection with the Service. We may share your information with selected third parties, including:</a><ul class="ez-toc-list-level-3">
                      <li class="ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-11 __mPS2id _mPS2id-h"
                          href="#Third_Party_Service_Providers_and_Partners_Business_partners_vendors_suppliers_and_subcontractors_who_perform_services_on_our_behalf_these_companies_are_authorized_to_use_your_personal_information_only_as_necessary_to_provide_these_services_to_us"
                          title="Third Party Service Providers and Partners. Business partners, vendors, suppliers, and subcontractors who perform services on our behalf (these companies are authorized to use your personal information only as necessary to provide these services to us);"
                        >Third Party Service Providers and Partners. Business partners, vendors, suppliers, and subcontractors who perform services on our behalf (these companies are authorized to use your personal information only as necessary to provide these services to us);</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-12 __mPS2id _mPS2id-h"
                          href="#Technical_Providers_Analytics_and_search_engine_providers_that_assist_us_in_the_improvement_and_optimization_of_our_Website"
                          title="Technical Providers. Analytics and search engine providers that assist us in the improvement and optimization of our Website;"
                        >Technical Providers. Analytics and search engine providers that assist us in the improvement and optimization of our Website;</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-13 __mPS2id _mPS2id-h"
                          href="#Credit_Reference_Agencies_Credit_reference_agencies_for_the_purpose_of_assessing_your_credit_score_where_this_is_a_condition_of_us_entering_into_a_contract_with_you"
                          title="Credit Reference Agencies. Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you;"
                        >Credit Reference Agencies. Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you;</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-14 __mPS2id _mPS2id-h"
                          href="#Payment_Processors_Payment_processors_for_the_purpose_of_fulfilling_relevant_payment_transactions"
                          title="Payment Processors. Payment processors for the purpose of fulfilling relevant payment transactions;"
                        >Payment Processors. Payment processors for the purpose of fulfilling relevant payment transactions;</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-15 __mPS2id _mPS2id-h"
                          href="#Third_Parties_in_a_Business_Transaction_Personal_information_may_be_disclosed_to_third_parties_in_connection_with_a_transaction_such_as_a_merger_sale_of_assets_or_shares_reorganization_financing_change_of_control_or_acquisition_of_all_or_a_portion_of_our_business_If_we_are_involved_in_a_merger_acquisition_or_sale_of_all_or_a_portion_of_its_assets_you_will_be_notified_via_email_andor_a_prominent_notice_on_our_Website_of_any_change_in_ownership_or_uses_of_your_personal_information_as_well_as_any_choices_you_may_have_regarding_your_personal_information"
                          title="Third Parties in a Business Transaction. Personal information may be disclosed to third parties in connection with a transaction, such as a merger, sale of assets or shares, reorganization, financing, change of control or acquisition of all or a portion of our business. If we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information."
                        >Third Parties in a Business Transaction. Personal information may be disclosed to third parties in connection with a transaction, such as a merger, sale of assets or shares, reorganization, financing, change of control or acquisition of all or a portion of our business. If we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.</a>
                      </li>
                    </ul>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-16 __mPS2id _mPS2id-h"
                      href="#In_certain_situations_we_may_be_required_to_disclose_personal_information_in_response_to_lawful_requests_by_public_authorities_including_to_meet_law_enforcement_requirements"
                      title="In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet law enforcement requirements."
                    >In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet law enforcement requirements.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-17 __mPS2id _mPS2id-h"
                      href="#We_may_be_under_a_duty_to_disclose_or_share_your_personal_data_in_order_to_comply_with_any_legal_obligation_or_in_order_to_enforce_or_apply_our_terms_and_conditions_of_service_URL_and_other_agreements_or_to_protect_the_rights_property_or_safety_of_us_our_customers_or_others_This_includes_exchanging_information_with_other_companies_and_organizations_for_the_purposes_of_fraud_protection_and_credit_risk_reduction"
                      title="We may be under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions of service ([URL]) and other agreements; or to protect the rights, property, or safety of us, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction."
                    >We may be under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions of service ([URL]) and other agreements; or to protect the rights, property, or safety of us, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-18 __mPS2id _mPS2id-h"
                      href="#Publicly_accessible_blogs_Our_Website_includes_publicly_accessible_blogs_or_community_forums_Any_information_you_provide_in_these_areas_may_be_read_collected_and_used_by_others_who_access_them_This_includes_information_posted_on_our_public_social_media_accounts_To_request_removal_of_your_personal_information_from_our_blog_or_community_forum_contact_us_at_PRIVACY_EMAIL_In_some_cases_we_may_not_be_able_to_remove_your_personal_information_in_which_case_we_will_let_you_know_if_we_are_unable_to_do_so_and_why"
                      title="Publicly accessible blogs. Our Website includes publicly accessible blogs or community forums. Any information you provide in these areas may be read, collected and used by others who access them. This includes information posted on our public social media accounts. To request removal of your personal information from our blog or community forum, contact us at [PRIVACY EMAIL]. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why."
                    >Publicly accessible blogs. Our Website includes publicly accessible blogs or community forums. Any information you provide in these areas may be read, collected and used by others who access them. This includes information posted on our public social media accounts. To request removal of your personal information from our blog or community forum, contact us at [PRIVACY EMAIL]. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-19 __mPS2id _mPS2id-h"
                      href="#Testimonials_With_consent_we_may_display_personal_testimonials_of_satisfied_customers_on_our_site_along_with_other_endorsements_If_you_wish_to_update_or_delete_your_testimonial_you_can_contact_us_at_PRIVACY_EMAIL"
                      title="Testimonials. With consent, we may display personal testimonials of satisfied customers on our site, along with other endorsements. If you wish to update or delete your testimonial, you can contact us at [PRIVACY EMAIL]."
                    >Testimonials. With consent, we may display personal testimonials of satisfied customers on our site, along with other endorsements. If you wish to update or delete your testimonial, you can contact us at [PRIVACY EMAIL].</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-20 __mPS2id _mPS2id-h"
                  href="#MARKETING_AND_ADVERTISING"
                  title="MARKETING AND ADVERTISING"
                >MARKETING AND ADVERTISING</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-21 __mPS2id _mPS2id-h"
                      href="#From_time_to_time_we_may_contact_you_with_relevant_information_about_the_Service_and_our_other_products_and_services_Most_messages_will_be_sent_electronically_For_some_messages_we_may_use_personal_information_we_collect_about_you_to_help_us_determine_the_most_relevant_information_to_share_with_you"
                      title="From time to time we may contact you with relevant information about the Service and our other products and services. Most messages will be sent electronically. For some messages, we may use personal information we collect about you to help us determine the most relevant information to share with you."
                    >From time to time we may contact you with relevant information about the Service and our other products and services. Most messages will be sent electronically. For some messages, we may use personal information we collect about you to help us determine the most relevant information to share with you.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-22 __mPS2id _mPS2id-h"
                      href="#If_you_do_not_want_to_receive_such_messages_from_us_you_will_be_able_to_tell_us_by_selecting_certain_boxes_on_forms_we_use_when_we_first_collect_your_contact_details_You_can_also_change_your_marketing_preferences_at_any_time_by_DESCRIBE_PROCESS_or_by_following_the_unsubscribe_link_at_the_bottom_of_our_emails"
                      title="If you do not want to receive such messages from us, you will be able to tell us by selecting certain boxes on forms we use when we first collect your contact details. You can also change your marketing preferences at any time by [DESCRIBE PROCESS]&nbsp;or by following the unsubscribe link at the bottom of our emails."
                    >If you do not want to receive such messages from us, you will be able to tell us by selecting certain boxes on forms we use when we first collect your contact details. You can also change your marketing preferences at any time by [DESCRIBE PROCESS]&nbsp;or by following the unsubscribe link at the bottom of our emails.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-23 __mPS2id _mPS2id-h"
                  href="#STORING_AND_TRANSFERRING_YOUR_PERSONAL_INFORMATION"
                  title="STORING AND TRANSFERRING YOUR PERSONAL INFORMATION"
                >STORING AND TRANSFERRING YOUR PERSONAL INFORMATION</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-24 __mPS2id _mPS2id-h"
                      href="#Security_All_information_you_provide_to_us_is_stored_on_secure_servers_in_a_controlled_environment_with_limited_access_Any_payment_transactions_will_be_encrypted_in_transit_using_SSL_technology_Where_we_have_given_you_or_where_you_have_chosen_a_password_which_enables_you_to_access_certain_parts_of_our_Service_you_are_responsible_for_keeping_this_password_confidential_Subscribers_should_not_share_their_password_with_anyone"
                      title="Security. All information you provide to us is stored on secure servers in a controlled environment with limited access. Any payment transactions will be encrypted in transit using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Service, you are responsible for keeping this password confidential. Subscribers should not share their password with anyone."
                    >Security. All information you provide to us is stored on secure servers in a controlled environment with limited access. Any payment transactions will be encrypted in transit using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Service, you are responsible for keeping this password confidential. Subscribers should not share their password with anyone.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-25 __mPS2id _mPS2id-h"
                      href="#While_no_transmission_of_information_via_the_internet_is_completely_secure_we_take_reasonable_measures_to_protect_your_personal_information_We_cannot_guarantee_the_security_of_your_personal_information_transmitted_to_our_Website_any_transmission_is_at_your_own_risk_Once_we_have_received_your_information_we_will_use_strict_procedures_and_security_features_to_try_to_prevent_unauthorized_access"
                      title="While no transmission of information via the internet is completely secure, we take reasonable measures to protect your personal information. We cannot guarantee the security of your personal information transmitted to our Website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access."
                    >While no transmission of information via the internet is completely secure, we take reasonable measures to protect your personal information. We cannot guarantee the security of your personal information transmitted to our Website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-26 __mPS2id _mPS2id-h"
                      href="#International_Transfers_of_your_Personal_Information_The_personal_information_we_collect_may_be_transferred_to_and_stored_in_countries_outside_of_the_jurisdiction_you_are_in_where_we_and_our_third-party_service_providers_have_operations_If_you_are_located_in_the_European_Union_EU_your_personal_information_will_be_processed_outside_of_the_EU_including_in_the_United_States_these_international_transfers_of_your_personal_information_are_made_pursuant_to_appropriate_safeguards_such_as_standard_data_protection_clauses_adopted_by_the_European_Commission_If_you_wish_to_inquire_further_about_these_safeguards_used_please_contact_us_using_the_details_set_out_at_the_end_of_this_privacy_policy"
                      title="International Transfers of your Personal Information. The personal information we collect may be transferred to and stored in countries outside of the jurisdiction you are in where we and our third-party service providers have operations. If you are located in the European Union (“EU“), your personal information will be processed outside of the EU including in the United States; these international transfers of your personal information are made pursuant to appropriate safeguards, such as standard data protection clauses adopted by the European Commission. If you wish to inquire further about these safeguards used, please contact us using the details set out at the end of this privacy policy."
                    >International Transfers of your Personal Information. The personal information we collect may be transferred to and stored in countries outside of the jurisdiction you are in where we and our third-party service providers have operations. If you are located in the European Union (“EU“), your personal information will be processed outside of the EU including in the United States; these international transfers of your personal information are made pursuant to appropriate safeguards, such as standard data protection clauses adopted by the European Commission. If you wish to inquire further about these safeguards used, please contact us using the details set out at the end of this privacy policy.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-27 __mPS2id _mPS2id-h"
                  href="#RETAINING_YOUR_INFORMATION"
                  title="RETAINING YOUR INFORMATION"
                >RETAINING YOUR INFORMATION</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-28 __mPS2id _mPS2id-h"
                      href="#We_will_only_retain_your_personal_information_for_as_long_as_necessary_to_fulfill_the_purposes_we_collected_it_for_including_for_the_purposes_of_our_legitimate_business_interests_and_satisfying_any_legal_or_reporting_requirements"
                      title="We will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of our legitimate business interests and satisfying any legal or reporting requirements."
                    >We will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of our legitimate business interests and satisfying any legal or reporting requirements.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-29 __mPS2id _mPS2id-h"
                      href="#To_determine_the_appropriate_retention_period_for_personal_information_we_consider_the_amount_nature_and_sensitivity_of_the_personal_information_the_potential_risk_of_harm_from_unauthorized_use_or_disclosure_of_your_personal_information_the_purposes_for_which_we_process_your_personal_information_and_the_applicable_legal_requirements"
                      title="To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and the applicable legal requirements."
                    >To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and the applicable legal requirements.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-30 __mPS2id _mPS2id-h"
                  href="#YOUR_RIGHTS_IN_RESPECT_OF_YOUR_PERSONAL_INFORMATION"
                  title="YOUR RIGHTS IN RESPECT OF YOUR PERSONAL INFORMATION"
                >YOUR RIGHTS IN RESPECT OF YOUR PERSONAL INFORMATION</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-31 __mPS2id _mPS2id-h"
                      href="#In_accordance_with_applicable_privacy_law_you_have_the_following_rights_in_respect_of_your_personal_information_that_we_hold"
                      title="In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold:"
                    >In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold:</a><ul class="ez-toc-list-level-3">
                      <li class="ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-32 __mPS2id _mPS2id-h"
                          href="#Right_of_access_and_portability_The_right_to_obtain_access_to_your_personal_information_along_with_certain_information_and_to_receive_that_personal_information_in_a_commonly_used_format_and_to_have_it_ported_to_another_data_controller"
                          title="Right of access and portability. The right to obtain access to your personal information along with certain information, and to receive that personal information in a commonly used format and to have it ported to another data controller."
                        >Right of access and portability. The right to obtain access to your personal information along with certain information, and to receive that personal information in a commonly used format and to have it ported to another data controller.</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-33 __mPS2id _mPS2id-h"
                          href="#Right_to_rectification_The_right_to_obtain_rectification_of_your_personal_information_without_undue_delay_where_that_personal_information_is_inaccurate_or_incomplete"
                          title="Right to rectification. The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete."
                        >Right to rectification. The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete.</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-34 __mPS2id _mPS2id-h"
                          href="#Right_to_erasure_The_right_to_obtain_the_erasure_of_your_personal_information_without_undue_delay_in_certain_circumstances_such_as_where_the_personal_information_is_no_longer_necessary_in_relation_to_the_purposes_for_which_it_was_collected_or_processed"
                          title="Right to erasure. The right to obtain the erasure of your personal information without undue delay in certain circumstances, such as where the personal information is no longer necessary in relation to the purposes for which it was collected or processed."
                        >Right to erasure. The right to obtain the erasure of your personal information without undue delay in certain circumstances, such as where the personal information is no longer necessary in relation to the purposes for which it was collected or processed.</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-35 __mPS2id _mPS2id-h"
                          href="#Right_to_restriction_The_right_to_obtain_the_restriction_of_the_processing_undertaken_by_us_on_your_personal_information_in_certain_circumstances_such_as_where_the_accuracy_of_the_personal_information_is_contested_by_you_for_a_period_enabling_us_to_verify_the_accuracy_of_that_personal_information"
                          title="Right to restriction. The right to obtain the restriction of the processing undertaken by us on your personal information in certain circumstances, such as where the accuracy of the personal information is contested by you, for a period enabling us to verify the accuracy of that personal information."
                        >Right to restriction. The right to obtain the restriction of the processing undertaken by us on your personal information in certain circumstances, such as where the accuracy of the personal information is contested by you, for a period enabling us to verify the accuracy of that personal information.</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-36 __mPS2id _mPS2id-h"
                          href="#Right_to_object_The_right_to_object_on_grounds_relating_to_your_particular_situation_to_the_processing_of_your_personal_information_and_to_object_to_the_processing_of_your_personal_information_for_direct_marketing_purposes_to_the_extent_it_is_related_to_such_direct_marketing"
                          title="Right to object. The right to object, on grounds relating to your particular situation, to the processing of your personal information, and to object to the processing of your personal information for direct marketing purposes, to the extent it is related to such direct marketing."
                        >Right to object. The right to object, on grounds relating to your particular situation, to the processing of your personal information, and to object to the processing of your personal information for direct marketing purposes, to the extent it is related to such direct marketing.</a>
                      </li>
                    </ul>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-37 __mPS2id _mPS2id-h"
                      href="#If_you_wish_to_exercise_one_of_these_rights_please_contact_us_using_the_contact_details_at_the_end_of_this_privacy_policy_Upon_request_we_will_provide_you_with_information_about_whether_we_hold_any_of_your_personal_information_You_may_access_correct_or_request_deletion_of_your_personal_information_by_logging_into_your_account_or_by_contacting_us_at_PRIVACY_EMAIL_We_will_respond_to_your_request_within_30_days"
                      title="If you wish to exercise one of these rights, please contact us using the contact details at the end of this privacy policy. Upon request, we will provide you with information about whether we hold any of your personal information. You may access, correct or request deletion of your personal information by logging into your account, or by contacting us at [PRIVACY EMAIL]. We will respond to your request within 30 days."
                    >If you wish to exercise one of these rights, please contact us using the contact details at the end of this privacy policy. Upon request, we will provide you with information about whether we hold any of your personal information. You may access, correct or request deletion of your personal information by logging into your account, or by contacting us at [PRIVACY EMAIL]. We will respond to your request within 30 days.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-38 __mPS2id _mPS2id-h"
                      href="#If_you_reside_in_the_European_Union_EU_we_are_the_controller_of_your_personal_information_for_purposes_of_European_data_protection_legislation_You_also_have_the_right_to_lodge_a_complaint_to_your_local_data_protection_authority_Information_about_how_to_contact_your_local_data_protection_authority_is_available_at_eceuropaeujusticedata-protectionbodiesauthoritiesindex_enhtm"
                      title="If you reside in the European Union (EU), we are the controller of your personal information for purposes of European data protection legislation. You also have the right to lodge a complaint to your local data protection authority. Information about how to contact your local data protection authority is available at&nbsp;ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm."
                    >If you reside in the European Union (EU), we are the controller of your personal information for purposes of European data protection legislation. You also have the right to lodge a complaint to your local data protection authority. Information about how to contact your local data protection authority is available at&nbsp;ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-39 __mPS2id _mPS2id-h"
                  href="#COOKIES_AND_SIMILAR_TECHNOLOGIES"
                  title="COOKIES AND SIMILAR TECHNOLOGIES"
                >COOKIES AND SIMILAR TECHNOLOGIES</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-40 __mPS2id _mPS2id-h"
                      href="#Our_Service_uses_cookies_and_similar_technologies_collectively_referred_to_as_cookies_to_distinguish_you_from_other_users_of_our_Service_This_helps_us_to_provide_you_with_good_service_This_helps_us_enhance_and_personalize_your_user_experience_to_monitor_and_improve_our_Website_and_services_and_for_other_internal_purposes_As_is_true_of_most_websites_we_gather_certain_information_automatically_This_information_we_may_collect_is_described_in_detail_in_Table_2"
                      title="Our Service uses cookies and similar technologies (collectively referred to as cookies) to distinguish you&nbsp;from other users of our Service. This helps us to provide you with good service. This helps us enhance and personalize your user experience, to monitor and improve our Website and services, and for other internal purposes. As is true of most websites, we gather certain information automatically. This information we may collect is described in detail in Table 2."
                    >Our Service uses cookies and similar technologies (collectively referred to as cookies) to distinguish you&nbsp;from other users of our Service. This helps us to provide you with good service. This helps us enhance and personalize your user experience, to monitor and improve our Website and services, and for other internal purposes. As is true of most websites, we gather certain information automatically. This information we may collect is described in detail in Table 2.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-41 __mPS2id _mPS2id-h"
                      href="#We_use_the_following_types_of_cookies"
                      title="We use the following types of cookies:"
                    >We use the following types of cookies:</a><ul class="ez-toc-list-level-3">
                      <li class="ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-42 __mPS2id _mPS2id-h"
                          href="#Strictly_necessary_cookies_These_cookies_are_required_for_the_essential_operation_of_our_Service_such_as_to_authenticate_you_and_prevent_fraudulent_use"
                          title="Strictly necessary cookies. These cookies are required for the essential operation of our Service such as to authenticate you and prevent fraudulent use."
                        >Strictly necessary cookies. These cookies are required for the essential operation of our Service such as to authenticate you and prevent fraudulent use.</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-43 __mPS2id _mPS2id-h"
                          href="#Analyticalperformance_cookies_These_cookies_allow_us_to_recognize_and_count_the_number_of_visitors_and_to_see_how_visitors_move_around_our_Service_when_they_are_using_it_This_helps_us_to_improve_the_way_our_Service_works_for_example_by_ensuring_that_you_can_find_information_easily"
                          title="Analytical/performance cookies. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Service when they are using it. This helps us to improve the way our Service works, for example, by ensuring that you can find information easily."
                        >Analytical/performance cookies. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Service when they are using it. This helps us to improve the way our Service works, for example, by ensuring that you can find information easily.</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-44 __mPS2id _mPS2id-h"
                          href="#Functionality_cookies_These_cookies_are_used_to_recognize_you_when_you_return_to_our_Service_This_enables_us_to_personalize_our_content_for_you_greet_you_by_name_and_remember_your_preferences_for_example_your_choice_of_language_or_region"
                          title="Functionality cookies. These cookies are used to recognize you when you return to our Service. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region)."
                        >Functionality cookies. These cookies are used to recognize you when you return to our Service. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</a>
                      </li><li class="ez-toc-page-1 ez-toc-heading-level-3">
                        <a
                          class="ez-toc-link ez-toc-heading-45 __mPS2id _mPS2id-h"
                          href="#Targeting_cookies_These_cookies_record_your_visit_to_our_Service_the_pages_you_have_visited_and_the_links_you_have_followed_We_will_use_this_information_to_make_our_Service_and_the_advertising_displayed_on_it_more_relevant_to_your_interests_We_may_also_share_this_information_with_third_parties_for_this_purpose"
                          title="Targeting cookies. These cookies record your visit to our Service, the pages you have visited and the links you have followed. We will use this information to make our Service and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose."
                        >Targeting cookies. These cookies record your visit to our Service, the pages you have visited and the links you have followed. We will use this information to make our Service and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</a>
                      </li>
                    </ul>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-46 __mPS2id _mPS2id-h"
                      href="#Please_note_that_third_parties_including_for_example_advertising_networks_and_providers_of_external_services_like_web_traffic_analysis_services_may_also_use_cookies_over_which_we_have_no_control_These_cookies_are_likely_to_be_analyticalperformance_cookies_or_targeting_cookies"
                      title="Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies."
                    >Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-47 __mPS2id _mPS2id-h"
                      href="#We_may_partner_with_a_third_party_to_either_display_advertising_on_our_Website_or_to_manage_our_advertising_on_other_sites_Our_third-party_partner_may_use_technologies_such_as_cookies_to_gather_information_about_your_activities_on_this_Website_and_other_sites_in_order_to_provide_you_advertising_based_upon_your_browsing_activities_and_interests_If_you_wish_to_not_have_this_information_used_to_serve_you_interest-based_ads_you_may_opt-out_by_contacting_us_Please_note_this_does_not_opt_you_out_of_being_served_ads_You_will_continue_to_receive_generic_ads"
                      title="We may partner with a third party to either display advertising on our Website or to manage our advertising on other sites. Our third-party partner may use technologies such as cookies to gather information about your activities on this Website and other sites in order to provide you advertising based upon your browsing activities and interests. If you wish to not have this information used to serve you interest-based ads, you may opt-out by contacting us. Please note this does not opt you out of being served ads. You will continue to receive generic ads."
                    >We may partner with a third party to either display advertising on our Website or to manage our advertising on other sites. Our third-party partner may use technologies such as cookies to gather information about your activities on this Website and other sites in order to provide you advertising based upon your browsing activities and interests. If you wish to not have this information used to serve you interest-based ads, you may opt-out by contacting us. Please note this does not opt you out of being served ads. You will continue to receive generic ads.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-48 __mPS2id _mPS2id-h"
                      href="#You_block_cookies_by_activating_the_setting_on_your_browser_that_allows_you_to_refuse_the_setting_of_all_or_some_cookies_However_if_you_use_your_browser_settings_to_block_all_cookies_including_strictly_necessary_cookies_you_may_not_be_able_to_access_all_or_parts_of_our_site"
                      title="You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including strictly necessary cookies) you may not be able to access all or parts of our site."
                    >You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including strictly necessary cookies) you may not be able to access all or parts of our site.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-49 __mPS2id _mPS2id-h"
                  href="#i"
                  title="&nbsp;"
                >&nbsp;</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-50 __mPS2id _mPS2id-h"
                  href="#LINKS_TO_THIRD_PARTY_SITES"
                  title="LINKS TO THIRD PARTY SITES"
                >LINKS TO THIRD PARTY SITES</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-51 __mPS2id _mPS2id-h"
                      href="#The_Service_may_from_time_to_time_contain_links_to_and_from_third_party_websites_If_you_follow_a_link_to_any_of_these_websites_please_note_that_these_websites_have_their_own_privacy_policies_and_that_we_do_not_accept_any_responsibility_or_liability_for_their_policies_Please_check_the_individual_policies_before_you_submit_any_information_to_those_websites"
                      title="The Service may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those websites."
                    >The Service may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those websites.</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-52 __mPS2id _mPS2id-h"
                      href="#Some_of_the_pages_on_our_Website_may_utilize_framing_techniques_to_serve_content_tofrom_our_partners_while_preserving_the_look_and_feel_of_our_Website_Please_be_aware_that_you_are_providing_your_personal_information_to_these_third_parties_and_not_us"
                      title="Some of the pages on our Website may utilize framing techniques to serve content to/from our partners while preserving the look and feel of our Website. Please be aware that you are providing your personal information to these third parties and not us."
                    >Some of the pages on our Website may utilize framing techniques to serve content to/from our partners while preserving the look and feel of our Website. Please be aware that you are providing your personal information to these third parties and not us.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-53 __mPS2id _mPS2id-h"
                  href="#USE_OF_SERVICE_BY_MINORS"
                  title="USE OF SERVICE BY MINORS"
                >USE OF SERVICE BY MINORS</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-54 __mPS2id _mPS2id-h"
                      href="#The_Service_is_not_directed_at_persons_under_16_and_we_do_not_intend_to_collect_personal_information_from_children_under_16_in_our_capacity_as_a_controller_If_you_become_aware_that_a_child_has_provided_us_with_personal_information_without_appropriate_consent_then_please_contact_us_using_the_details_below_so_that_we_can_take_the_appropriate_steps_in_accordance_with_our_legal_obligations_and_this_privacy_policy"
                      title="The Service is not directed at persons under 16 and we do not intend to collect personal information from children under 16 in our capacity as a controller. If you become aware that a child has provided us with personal information without appropriate consent, then please contact us using the details below so that we can take the appropriate steps in accordance with our legal obligations and this privacy policy."
                    >The Service is not directed at persons under 16 and we do not intend to collect personal information from children under 16 in our capacity as a controller. If you become aware that a child has provided us with personal information without appropriate consent, then please contact us using the details below so that we can take the appropriate steps in accordance with our legal obligations and this privacy policy.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-55 __mPS2id _mPS2id-h"
                  href="#YOUR_CANADIAN_PRIVACY_RIGHTS"
                  title="YOUR CANADIAN PRIVACY RIGHTS"
                >YOUR CANADIAN PRIVACY RIGHTS</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-56 __mPS2id _mPS2id-h"
                      href="#The_Personal_Information_Protection_and_Electronic_Documents_Act_Schedule_1_Clause_49_permits_Canadian_residents_that_are_users_of_our_website_to_request_certain_information_regarding_the_existence_use_and_disclosure_of_personal_information_and_authorizes_access_to_that_information_To_make_such_a_request_please_send_an_email_to_PRIVACY_EMAIL"
                      title="The Personal Information Protection and Electronic Documents Act, Schedule 1, Clause 4.9 permits Canadian residents that are users of our website to request certain information regarding the existence, use, and disclosure of personal information and authorizes access to that information. To make such a request, please send an email to [PRIVACY EMAIL]."
                    >The Personal Information Protection and Electronic Documents Act, Schedule 1, Clause 4.9 permits Canadian residents that are users of our website to request certain information regarding the existence, use, and disclosure of personal information and authorizes access to that information. To make such a request, please send an email to [PRIVACY EMAIL].</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-57 __mPS2id _mPS2id-h"
                  href="#CHANGES_TO_THIS_POLICY"
                  title="CHANGES TO THIS POLICY"
                >CHANGES TO THIS POLICY</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-58 __mPS2id _mPS2id-h"
                      href="#We_may_update_this_privacy_policy_from_time_to_time_and_so_you_should_review_this_page_periodically_When_we_change_this_privacy_policy_in_a_material_way_we_will_update_the_last_modified_date_at_the_end_of_this_privacy_policy_Changes_to_this_privacy_policy_are_effective_when_they_are_posted_on_this_page"
                      title="We may update this privacy policy from time to time and so you should review this page periodically. When we change this privacy policy in a material way, we will update the “last modified” date at the end of this privacy policy. Changes to this privacy policy are effective when they are posted on this page."
                    >We may update this privacy policy from time to time and so you should review this page periodically. When we change this privacy policy in a material way, we will update the “last modified” date at the end of this privacy policy. Changes to this privacy policy are effective when they are posted on this page.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-59 __mPS2id _mPS2id-h"
                  href="#NOTICE_TO_YOU"
                  title="NOTICE TO YOU"
                >NOTICE TO YOU</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-60 __mPS2id _mPS2id-h"
                      href="#If_we_need_to_provide_you_with_information_about_something_whether_for_legal_marketing_or_other_business-related_purposes_we_will_select_what_we_believe_is_the_best_way_to_get_in_contact_with_you_We_will_usually_do_this_through_email_or_by_placing_a_notice_on_our_Website_The_fact_that_we_may_send_notices_to_you_will_not_stop_you_from_being_able_to_opt_out_of_certain_types_of_contact_as_described_in_this_privacy_policy"
                      title="If we need to provide you with information about something, whether for legal, marketing or other business-related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on our Website. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this privacy policy."
                    >If we need to provide you with information about something, whether for legal, marketing or other business-related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on our Website. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this privacy policy.</a>
                  </li>
                </ul>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-1">
                <a
                  class="ez-toc-link ez-toc-heading-61 __mPS2id _mPS2id-h"
                  href="#CONTACTING_US"
                  title="CONTACTING US"
                >CONTACTING US</a><ul class="ez-toc-list-level-2">
                  <li class="ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-62 __mPS2id _mPS2id-h"
                      href="#Regardless_of_your_location_any_questions_comments_and_requests_regarding_this_privacy_policy_are_welcome_and_should_be_addressed_to_our_Data_Protection_Officer_NAME_at_PRIVACY_EMAIL_Communication_can_also_be_addressed_to"
                      title="Regardless of your location, any questions, comments, and requests regarding this privacy policy are welcome and should be addressed to our Data Protection Officer, [NAME], at [PRIVACY EMAIL]. Communication can also be addressed to:"
                    >Regardless of your location, any questions, comments, and requests regarding this privacy policy are welcome and should be addressed to our Data Protection Officer, [NAME], at [PRIVACY EMAIL]. Communication can also be addressed to:</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-63 __mPS2id _mPS2id-h"
                      href="#THIS_PRIVACY_POLICY_WAS_LAST_MODIFIED_ON_DATE"
                      title="THIS PRIVACY POLICY WAS LAST MODIFIED ON [DATE]"
                    >THIS PRIVACY POLICY WAS LAST MODIFIED ON [DATE]</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-64 __mPS2id _mPS2id-h"
                      href="#Who_we_are"
                      title="Who we are"
                    >Who we are</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-65 __mPS2id _mPS2id-h"
                      href="#Quickbooks_Data_Sharing"
                      title="Quickbooks Data Sharing"
                    >Quickbooks Data Sharing</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-66 __mPS2id _mPS2id-h"
                      href="#Comments"
                      title="Comments"
                    >Comments</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-67 __mPS2id _mPS2id-h"
                      href="#Media"
                      title="Media"
                    >Media</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-68 __mPS2id _mPS2id-h"
                      href="#Cookies"
                      title="Cookies"
                    >Cookies</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-69 __mPS2id _mPS2id-h"
                      href="#Embedded_content_from_other_websites"
                      title="Embedded content from other websites"
                    >Embedded content from other websites</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-70 __mPS2id _mPS2id-h"
                      href="#Who_we_share_your_data_with"
                      title="Who we share your data with"
                    >Who we share your data with</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-71 __mPS2id _mPS2id-h"
                      href="#How_long_we_retain_your_data"
                      title="How long we retain your data"
                    >How long we retain your data</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-72 __mPS2id _mPS2id-h"
                      href="#What_rights_you_have_over_your_data"
                      title="What rights you have over your data"
                    >What rights you have over your data</a>
                  </li><li class="ez-toc-page-1 ez-toc-heading-level-2">
                    <a
                      class="ez-toc-link ez-toc-heading-73 __mPS2id _mPS2id-h"
                      href="#Where_your_data_is_sent"
                      title="Where your data is sent"
                    >Where your data is sent</a>
                  </li>
                </ul>
              </li>
            </ul></nav></div>
          <p />
          <h2><span
            id="DETAILED_TERMS"
            class="ez-toc-section _mPS2id-t"
          />DETAILED TERMS<span class="ez-toc-section-end" /></h2>
          <h3><span
            id="PERSONAL_INFORMATION_WE_COLLECT_ABOUT_YOU_AND_HOW_WE_USE_IT"
            class="ez-toc-section _mPS2id-t"
          />PERSONAL INFORMATION WE COLLECT ABOUT YOU AND HOW WE USE IT<span class="ez-toc-section-end" /></h3>
          <p>Information you give to us. We collect personal information about you when you voluntarily submit information directly to us by filling in forms on our Website or by corresponding with us by phone, email or otherwise. This includes information you provide when you register to use our Website, subscribe to the Service, participate in any discussion boards, forums or other social media functions on our site or enter a competition, promotion or survey, and when you report a problem with our Website, or use some other feature of the Service as available from time to time. If you choose not to provide personal information, we may not be able to provide the Service to you or respond to your other requests. Information we receive from other sources. We may receive personal information about you from individuals or corporate entities which are subscribers to the Service (“Subscribers”) where you are to be designated a user of the Service. We may receive personal information about you if you use any of the other websites we operate or the other services we provide from time to time. We also work closely with third parties (including, for example, subcontractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies) and may receive information about you from them, subject to your agreements with them. The following table (“<strong>Table 1</strong>”) sets out the categories of personal information you provide to us and that we receive from other sources, how we use that information, and the legal basis which we rely upon to process the information and determine applicable retention periods: <b>Personal Information We Collect</b></p>
          <table>
            <tbody>
              <tr>
                <td><b>Category of Personal Information</b></td>
                <td><b>How We Use It</b></td>
                <td><b>Legal Basis for the Processing</b></td>
              </tr>
              <tr>
                <td rowspan="2">
                  Contact information and basic personal details. Your name, phone number, address, location, IP address, e-mail address.
                </td>
                <td rowspan="2">
                  We use this information to: <br>• Communicate with you, including sending statements, news, alerts and marketing communications. <br>• Deal with inquiries and other requests made by or about you, including customer service issues, relating to the Service. <br>• Operate, maintain and provide to you the features and functionality of the Service.
                </td>
                <td>The processing is necessary for our legitimate interests, namely for marketing purposes, and for communicating with you effectively and responding to your queries.</td>
              </tr>
              <tr>
                <td>The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (namely our Terms of Service).</td>
              </tr>
              <tr>
                <td>Email account, username and password.</td>
                <td>Where you have chosen to import contacts from your Outlook or other email account address book to invite them to become members of our Website, we collect the username and password for the email account you wish to import your contacts from</td>
                <td>The processing is necessary for the performance of a contract and to take steps prior to entering into a contract (namely our Terms of Service).</td>
              </tr>
              <tr>
                <td>Correspondence and comments. When you contact us directly, e.g. by email, phone, mail, or when you interact with customer service, we will record your comments and opinions.</td>
                <td>To address your questions, issues, and concerns and resolve your customer service issues.</td>
                <td>The processing is necessary for our legitimate interests, namely communicating with you effectively for the purposes of resolving your issues.</td>
              </tr>
              <tr>
                <td>Payment information. Details such as your credit card or other financial information including credit scores obtained from credit reference agencies.</td>
                <td>We use this information to facilitate payment for use of the Service, to assess your credit score and to detect and prevent fraud.</td>
                <td>The processing for assessing your credit score and facilitating payment is necessary for the performance of our contract with you (namely our Terms of Service). &nbsp;The processing is necessary for our legitimate interests, namely the detection and prevention of fraud.</td>
              </tr>
              <tr>
                <td>Recruiting details. Contact information and basic personal details (as set out above); professional details and employment information such as resume, references, LinkedIn profile.</td>
                <td>We use this information to facilitate recruiting.</td>
                <td>The processing is necessary for our legitimate interests, namely assessing your suitability for a role with us.</td>
              </tr>
              <tr>
                <td>All personal information set out above.</td>
                <td>We will use all the personal information we collect to operate, maintain and provide to you the features and functionality of the Service, to monitor and improve the Service, our Website and business, for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes, to keep the Website safe and secure and to help us develop new products and services.</td>
                <td>The processing is necessary for our legitimate interest, namely to administer and improve the Service, our business and develop new services.</td>
              </tr>
            </tbody>
          </table>
          <h1><br><br><br><br><br><br></h1>
          <h2><span
            id="The_following_table_Table_2_sets_out_the_categories_of_personal_information_we_collect_about_you_automatically_how_we_use_that_information_and_the_legal_basis_which_we_rely_upon_to_process_the_information_and_determine_applicable_retention_periods"
            class="ez-toc-section _mPS2id-t"
          />The following table (“<b>Table 2</b>”) sets out the categories of personal information we collect about you automatically, how we use that information, and the legal basis which we rely upon to process the information and determine applicable retention periods:&nbsp;<span class="ez-toc-section-end" /></h2>
          <p><b>Personal Information Collected Automatically</b></p>
          <table>
            <tbody>
              <tr>
                <td><b>Category of Personal Information</b></td>
                <td><b>How We Use It</b></td>
                <td><b>Legal Basis for the Processing</b></td>
              </tr>
              <tr>
                <td>Information about how you access and use the Service. For example, the website from which you came and the website to which you are going when you leave our Website, your social media profiles, how frequently you access the Service, the time you access the Service and how long you use it for, whether you open emails or click the links contained in emails, whether you access the Service from multiple devices, and other actions you take on the Service. We also gather information, which may include Internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data.</td>
                <td>We use this information to: <br>• Conduct market analysis, monitor the Service and how it is used in order to improve our business and help us develop new products and services; <br>• Generate marketing leads and determine news, alerts and other products and services that may be of interest to you for marketing purposes.</td>
                <td>The processing is necessary for our legitimate interests, namely: to conduct relevant analysis to improve the Service generally and for marketing purposes.</td>
              </tr>
              <tr>
                <td>Information about your device. We also collect information about the computer, tablet, smartphone or other electronic devices you use to connect to the Service. This information can include details about the type of device, unique device identifying numbers, operating systems, browsers, and applications connected to the Service through the device, your Internet service provider or mobile network, your IP address.</td>
                <td>We use this information to: <br>• Enable the Service to be presented to you on your device; and <br>• Operate, maintain, and provide to you the features and functionality of the Service. <br>• Monitor and improve the Service and business and to help us develop new products and services.</td>
                <td>The processing is necessary for the performance of a contract (namely our Terms of Service).&nbsp; <br>The processing is necessary for our legitimate interests, namely to tailor the Service to the user and to improve the Service generally.</td>
              </tr>
            </tbody>
          </table>
          <h2><span
            id="We_may_link_or_combine_the_personal_information_we_collect_andor_receive_about_you_and_the_information_we_collect_automatically_This_allows_us_to_provide_you_with_a_personalized_experience_regardless_of_how_you_interact_with_us"
            class="ez-toc-section _mPS2id-t"
          />We may link or combine the personal information we collect and/or receive about you and the information we collect automatically. This allows us to provide you with a personalized experience regardless of how you interact with us.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="We_may_anonymize_and_aggregate_any_of_the_personal_information_we_collect_so_that_it_does_not_identify_you_We_may_use_anonymized_information_for_purposes_that_include_testing_our_IT_systems_research_data_analysis_improving_the_Service_and_developing_new_products_and_features_We_may_also_share_such_anonymized_information_with_others"
            class="ez-toc-section _mPS2id-t"
          />We may anonymize and aggregate any of the personal information we collect (so that it does not identify you). We may use anonymized information for purposes that include testing our IT systems, research, data analysis, improving the Service and developing new products and features. We may also share such anonymized information with others.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="DISCLOSURE_OF_YOUR_PERSONAL_INFORMATION"
            class="ez-toc-section _mPS2id-t"
          /><b>DISCLOSURE OF YOUR PERSONAL INFORMATION</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="We_do_not_sell_or_rent_your_personal_information_to_third_parties_for_their_direct_marking_purposes_without_your_explicit_consent"
            class="ez-toc-section _mPS2id-t"
          />We do not sell or rent your personal information to third parties for their direct marking purposes without your explicit consent.&nbsp;<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="We_may_share_your_personal_information_with_any_member_of_our_group_which_includes_our_subsidiaries_We_will_not_share_your_personal_information_with_any_third_parties_except_as_described_in_this_privacy_policy_or_in_connection_with_the_Service_We_may_share_your_information_with_selected_third_parties_including"
            class="ez-toc-section _mPS2id-t"
          />We may share your personal information with any member of our group, which includes our subsidiaries. We will not share your personal information with any third parties except as described in this privacy policy or in connection with the Service. We may share your information with selected third parties, including:<span class="ez-toc-section-end" /></h2>
          <h3><span
            id="Third_Party_Service_Providers_and_Partners_Business_partners_vendors_suppliers_and_subcontractors_who_perform_services_on_our_behalf_these_companies_are_authorized_to_use_your_personal_information_only_as_necessary_to_provide_these_services_to_us"
            class="ez-toc-section _mPS2id-t"
          /><b>Third Party Service Providers and Partners. </b>Business partners, vendors, suppliers, and subcontractors who perform services on our behalf (these companies are authorized to use your personal information only as necessary to provide these services to us);<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Technical_Providers_Analytics_and_search_engine_providers_that_assist_us_in_the_improvement_and_optimization_of_our_Website"
            class="ez-toc-section _mPS2id-t"
          /><b>Technical Providers. </b>Analytics and search engine providers that assist us in the improvement and optimization of our Website;<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Credit_Reference_Agencies_Credit_reference_agencies_for_the_purpose_of_assessing_your_credit_score_where_this_is_a_condition_of_us_entering_into_a_contract_with_you"
            class="ez-toc-section _mPS2id-t"
          /><b>Credit Reference Agencies. </b>Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you;<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Payment_Processors_Payment_processors_for_the_purpose_of_fulfilling_relevant_payment_transactions"
            class="ez-toc-section _mPS2id-t"
          /><b>Payment Processors. </b>Payment processors for the purpose of fulfilling relevant payment transactions;<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Third_Parties_in_a_Business_Transaction_Personal_information_may_be_disclosed_to_third_parties_in_connection_with_a_transaction_such_as_a_merger_sale_of_assets_or_shares_reorganization_financing_change_of_control_or_acquisition_of_all_or_a_portion_of_our_business_If_we_are_involved_in_a_merger_acquisition_or_sale_of_all_or_a_portion_of_its_assets_you_will_be_notified_via_email_andor_a_prominent_notice_on_our_Website_of_any_change_in_ownership_or_uses_of_your_personal_information_as_well_as_any_choices_you_may_have_regarding_your_personal_information"
            class="ez-toc-section _mPS2id-t"
          /><b>Third Parties in a Business Transaction. </b>Personal information may be disclosed to third parties in connection with a transaction, such as a merger, sale of assets or shares, reorganization, financing, change of control or acquisition of all or a portion of our business. If we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.<span class="ez-toc-section-end" /></h3>
          <h2><span
            id="In_certain_situations_we_may_be_required_to_disclose_personal_information_in_response_to_lawful_requests_by_public_authorities_including_to_meet_law_enforcement_requirements"
            class="ez-toc-section _mPS2id-t"
          />In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet law enforcement requirements.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="We_may_be_under_a_duty_to_disclose_or_share_your_personal_data_in_order_to_comply_with_any_legal_obligation_or_in_order_to_enforce_or_apply_our_terms_and_conditions_of_service_URL_and_other_agreements_or_to_protect_the_rights_property_or_safety_of_us_our_customers_or_others_This_includes_exchanging_information_with_other_companies_and_organizations_for_the_purposes_of_fraud_protection_and_credit_risk_reduction"
            class="ez-toc-section _mPS2id-t"
          />We may be under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions of service ([<b>URL</b>]) and other agreements; or to protect the rights, property, or safety of us, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="Publicly_accessible_blogs_Our_Website_includes_publicly_accessible_blogs_or_community_forums_Any_information_you_provide_in_these_areas_may_be_read_collected_and_used_by_others_who_access_them_This_includes_information_posted_on_our_public_social_media_accounts_To_request_removal_of_your_personal_information_from_our_blog_or_community_forum_contact_us_at_PRIVACY_EMAIL_In_some_cases_we_may_not_be_able_to_remove_your_personal_information_in_which_case_we_will_let_you_know_if_we_are_unable_to_do_so_and_why"
            class="ez-toc-section _mPS2id-t"
          /><b>Publicly accessible blogs</b>. Our Website includes publicly accessible blogs or community forums. Any information you provide in these areas may be read, collected and used by others who access them. This includes information posted on our public social media accounts. To request removal of your personal information from our blog or community forum, contact us at [<b>PRIVACY EMAIL</b>]. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="Testimonials_With_consent_we_may_display_personal_testimonials_of_satisfied_customers_on_our_site_along_with_other_endorsements_If_you_wish_to_update_or_delete_your_testimonial_you_can_contact_us_at_PRIVACY_EMAIL"
            class="ez-toc-section _mPS2id-t"
          /><b>Testimonials</b>. With consent, we may display personal testimonials of satisfied customers on our site, along with other endorsements. If you wish to update or delete your testimonial, you can contact us at [<b>PRIVACY EMAIL</b>].<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="MARKETING_AND_ADVERTISING"
            class="ez-toc-section _mPS2id-t"
          /><b>MARKETING AND ADVERTISING</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="From_time_to_time_we_may_contact_you_with_relevant_information_about_the_Service_and_our_other_products_and_services_Most_messages_will_be_sent_electronically_For_some_messages_we_may_use_personal_information_we_collect_about_you_to_help_us_determine_the_most_relevant_information_to_share_with_you"
            class="ez-toc-section _mPS2id-t"
          />From time to time we may contact you with relevant information about the Service and our other products and services. Most messages will be sent electronically. For some messages, we may use personal information we collect about you to help us determine the most relevant information to share with you.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="If_you_do_not_want_to_receive_such_messages_from_us_you_will_be_able_to_tell_us_by_selecting_certain_boxes_on_forms_we_use_when_we_first_collect_your_contact_details_You_can_also_change_your_marketing_preferences_at_any_time_by_DESCRIBE_PROCESS_or_by_following_the_unsubscribe_link_at_the_bottom_of_our_emails"
            class="ez-toc-section _mPS2id-t"
          />If you do not want to receive such messages from us, you will be able to tell us by selecting certain boxes on forms we use when we first collect your contact details. You can also change your marketing preferences at any time by [<b>DESCRIBE PROCESS</b>]&nbsp;or by following the unsubscribe link at the bottom of our emails.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="STORING_AND_TRANSFERRING_YOUR_PERSONAL_INFORMATION"
            class="ez-toc-section _mPS2id-t"
          /><b>STORING AND TRANSFERRING YOUR PERSONAL INFORMATION</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="Security_All_information_you_provide_to_us_is_stored_on_secure_servers_in_a_controlled_environment_with_limited_access_Any_payment_transactions_will_be_encrypted_in_transit_using_SSL_technology_Where_we_have_given_you_or_where_you_have_chosen_a_password_which_enables_you_to_access_certain_parts_of_our_Service_you_are_responsible_for_keeping_this_password_confidential_Subscribers_should_not_share_their_password_with_anyone"
            class="ez-toc-section _mPS2id-t"
          /><b>Security</b>. All information you provide to us is stored on secure servers in a controlled environment with limited access. Any payment transactions will be encrypted in transit using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Service, you are responsible for keeping this password confidential. Subscribers should not share their password with anyone.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="While_no_transmission_of_information_via_the_internet_is_completely_secure_we_take_reasonable_measures_to_protect_your_personal_information_We_cannot_guarantee_the_security_of_your_personal_information_transmitted_to_our_Website_any_transmission_is_at_your_own_risk_Once_we_have_received_your_information_we_will_use_strict_procedures_and_security_features_to_try_to_prevent_unauthorized_access"
            class="ez-toc-section _mPS2id-t"
          />While no transmission of information via the internet is completely secure, we take reasonable measures to protect your personal information. We cannot guarantee the security of your personal information transmitted to our Website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="International_Transfers_of_your_Personal_Information_The_personal_information_we_collect_may_be_transferred_to_and_stored_in_countries_outside_of_the_jurisdiction_you_are_in_where_we_and_our_third-party_service_providers_have_operations_If_you_are_located_in_the_European_Union_EU_your_personal_information_will_be_processed_outside_of_the_EU_including_in_the_United_States_these_international_transfers_of_your_personal_information_are_made_pursuant_to_appropriate_safeguards_such_as_standard_data_protection_clauses_adopted_by_the_European_Commission_If_you_wish_to_inquire_further_about_these_safeguards_used_please_contact_us_using_the_details_set_out_at_the_end_of_this_privacy_policy"
            class="ez-toc-section _mPS2id-t"
          /><b>International Transfers of your Personal Information</b>. The personal information we collect may be transferred to and stored in countries outside of the jurisdiction you are in where we and our third-party service providers have operations. If you are located in the European Union (“<b>EU</b>“), your personal information will be processed outside of the EU including in the United States; these international transfers of your personal information are made pursuant to appropriate safeguards, such as standard data protection clauses adopted by the European Commission. If you wish to inquire further about these safeguards used, please contact us using the details set out at the end of this privacy policy.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="RETAINING_YOUR_INFORMATION"
            class="ez-toc-section _mPS2id-t"
          /><b>RETAINING YOUR INFORMATION</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="We_will_only_retain_your_personal_information_for_as_long_as_necessary_to_fulfill_the_purposes_we_collected_it_for_including_for_the_purposes_of_our_legitimate_business_interests_and_satisfying_any_legal_or_reporting_requirements"
            class="ez-toc-section _mPS2id-t"
          />We will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of our legitimate business interests and satisfying any legal or reporting requirements.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="To_determine_the_appropriate_retention_period_for_personal_information_we_consider_the_amount_nature_and_sensitivity_of_the_personal_information_the_potential_risk_of_harm_from_unauthorized_use_or_disclosure_of_your_personal_information_the_purposes_for_which_we_process_your_personal_information_and_the_applicable_legal_requirements"
            class="ez-toc-section _mPS2id-t"
          />To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and the applicable legal requirements.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="YOUR_RIGHTS_IN_RESPECT_OF_YOUR_PERSONAL_INFORMATION"
            class="ez-toc-section _mPS2id-t"
          /><b>YOUR RIGHTS IN RESPECT OF YOUR PERSONAL INFORMATION</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="In_accordance_with_applicable_privacy_law_you_have_the_following_rights_in_respect_of_your_personal_information_that_we_hold"
            class="ez-toc-section _mPS2id-t"
          />In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold:<span class="ez-toc-section-end" /></h2>
          <h3><span
            id="Right_of_access_and_portability_The_right_to_obtain_access_to_your_personal_information_along_with_certain_information_and_to_receive_that_personal_information_in_a_commonly_used_format_and_to_have_it_ported_to_another_data_controller"
            class="ez-toc-section _mPS2id-t"
          /><b>Right of access and portability</b>. The right to obtain access to your personal information along with certain information, and to receive that personal information in a commonly used format and to have it ported to another data controller.<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Right_to_rectification_The_right_to_obtain_rectification_of_your_personal_information_without_undue_delay_where_that_personal_information_is_inaccurate_or_incomplete"
            class="ez-toc-section _mPS2id-t"
          /><b>Right to rectification</b>. The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete.<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Right_to_erasure_The_right_to_obtain_the_erasure_of_your_personal_information_without_undue_delay_in_certain_circumstances_such_as_where_the_personal_information_is_no_longer_necessary_in_relation_to_the_purposes_for_which_it_was_collected_or_processed"
            class="ez-toc-section _mPS2id-t"
          /><b>Right to erasure</b>. The right to obtain the erasure of your personal information without undue delay in certain circumstances, such as where the personal information is no longer necessary in relation to the purposes for which it was collected or processed.<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Right_to_restriction_The_right_to_obtain_the_restriction_of_the_processing_undertaken_by_us_on_your_personal_information_in_certain_circumstances_such_as_where_the_accuracy_of_the_personal_information_is_contested_by_you_for_a_period_enabling_us_to_verify_the_accuracy_of_that_personal_information"
            class="ez-toc-section _mPS2id-t"
          /><b>Right to restriction</b>. The right to obtain the restriction of the processing undertaken by us on your personal information in certain circumstances, such as where the accuracy of the personal information is contested by you, for a period enabling us to verify the accuracy of that personal information.<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Right_to_object_The_right_to_object_on_grounds_relating_to_your_particular_situation_to_the_processing_of_your_personal_information_and_to_object_to_the_processing_of_your_personal_information_for_direct_marketing_purposes_to_the_extent_it_is_related_to_such_direct_marketing"
            class="ez-toc-section _mPS2id-t"
          /><b>Right to object</b>. The right to object, on grounds relating to your particular situation, to the processing of your personal information, and to object to the processing of your personal information for direct marketing purposes, to the extent it is related to such direct marketing.<span class="ez-toc-section-end" /></h3>
          <h2><span
            id="If_you_wish_to_exercise_one_of_these_rights_please_contact_us_using_the_contact_details_at_the_end_of_this_privacy_policy_Upon_request_we_will_provide_you_with_information_about_whether_we_hold_any_of_your_personal_information_You_may_access_correct_or_request_deletion_of_your_personal_information_by_logging_into_your_account_or_by_contacting_us_at_PRIVACY_EMAIL_We_will_respond_to_your_request_within_30_days"
            class="ez-toc-section _mPS2id-t"
          />If you wish to exercise one of these rights, please contact us using the contact details at the end of this privacy policy. Upon request, we will provide you with information about whether we hold any of your personal information. You may access, correct or request deletion of your personal information by logging into your account, or by contacting us at [<b>PRIVACY EMAIL</b>]. We will respond to your request within 30 days.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="If_you_reside_in_the_European_Union_EU_we_are_the_controller_of_your_personal_information_for_purposes_of_European_data_protection_legislation_You_also_have_the_right_to_lodge_a_complaint_to_your_local_data_protection_authority_Information_about_how_to_contact_your_local_data_protection_authority_is_available_at_eceuropaeujusticedata-protectionbodiesauthoritiesindex_enhtm"
            class="ez-toc-section _mPS2id-t"
          />If you reside in the European Union (EU), we are the controller of your personal information for purposes of European data protection legislation. You also have the right to lodge a complaint to your local data protection authority. Information about how to contact your local data protection authority is available at&nbsp;<a
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            target="_blank"
            rel="noopener"
          >ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="COOKIES_AND_SIMILAR_TECHNOLOGIES"
            class="ez-toc-section _mPS2id-t"
          /><b>COOKIES AND SIMILAR TECHNOLOGIES</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="Our_Service_uses_cookies_and_similar_technologies_collectively_referred_to_as_cookies_to_distinguish_you_from_other_users_of_our_Service_This_helps_us_to_provide_you_with_good_service_This_helps_us_enhance_and_personalize_your_user_experience_to_monitor_and_improve_our_Website_and_services_and_for_other_internal_purposes_As_is_true_of_most_websites_we_gather_certain_information_automatically_This_information_we_may_collect_is_described_in_detail_in_Table_2"
            class="ez-toc-section _mPS2id-t"
          />Our Service uses cookies and similar technologies (collectively referred to as cookies) to distinguish you&nbsp;from other users of our Service. This helps us to provide you with good service. This helps us enhance and personalize your user experience, to monitor and improve our Website and services, and for other internal purposes. As is true of most websites, we gather certain information automatically. This information we may collect is described in detail in <b>Table 2</b>.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="We_use_the_following_types_of_cookies"
            class="ez-toc-section _mPS2id-t"
          />We use the following types of cookies:<span class="ez-toc-section-end" /></h2>
          <h3><span
            id="Strictly_necessary_cookies_These_cookies_are_required_for_the_essential_operation_of_our_Service_such_as_to_authenticate_you_and_prevent_fraudulent_use"
            class="ez-toc-section _mPS2id-t"
          /><b>Strictly necessary cookies</b>. These cookies are required for the essential operation of our Service such as to authenticate you and prevent fraudulent use.<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Analyticalperformance_cookies_These_cookies_allow_us_to_recognize_and_count_the_number_of_visitors_and_to_see_how_visitors_move_around_our_Service_when_they_are_using_it_This_helps_us_to_improve_the_way_our_Service_works_for_example_by_ensuring_that_you_can_find_information_easily"
            class="ez-toc-section _mPS2id-t"
          /><b>Analytical/performance cookies</b>. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Service when they are using it. This helps us to improve the way our Service works, for example, by ensuring that you can find information easily.<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Functionality_cookies_These_cookies_are_used_to_recognize_you_when_you_return_to_our_Service_This_enables_us_to_personalize_our_content_for_you_greet_you_by_name_and_remember_your_preferences_for_example_your_choice_of_language_or_region"
            class="ez-toc-section _mPS2id-t"
          /><b>Functionality cookies</b>. These cookies are used to recognize you when you return to our Service. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).<span class="ez-toc-section-end" /></h3>
          <h3><span
            id="Targeting_cookies_These_cookies_record_your_visit_to_our_Service_the_pages_you_have_visited_and_the_links_you_have_followed_We_will_use_this_information_to_make_our_Service_and_the_advertising_displayed_on_it_more_relevant_to_your_interests_We_may_also_share_this_information_with_third_parties_for_this_purpose"
            class="ez-toc-section _mPS2id-t"
          /><b>Targeting cookies</b>. These cookies record your visit to our Service, the pages you have visited and the links you have followed. We will use this information to make our Service and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.<span class="ez-toc-section-end" /></h3>
          <h2><span
            id="Please_note_that_third_parties_including_for_example_advertising_networks_and_providers_of_external_services_like_web_traffic_analysis_services_may_also_use_cookies_over_which_we_have_no_control_These_cookies_are_likely_to_be_analyticalperformance_cookies_or_targeting_cookies"
            class="ez-toc-section _mPS2id-t"
          />Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="We_may_partner_with_a_third_party_to_either_display_advertising_on_our_Website_or_to_manage_our_advertising_on_other_sites_Our_third-party_partner_may_use_technologies_such_as_cookies_to_gather_information_about_your_activities_on_this_Website_and_other_sites_in_order_to_provide_you_advertising_based_upon_your_browsing_activities_and_interests_If_you_wish_to_not_have_this_information_used_to_serve_you_interest-based_ads_you_may_opt-out_by_contacting_us_Please_note_this_does_not_opt_you_out_of_being_served_ads_You_will_continue_to_receive_generic_ads"
            class="ez-toc-section _mPS2id-t"
          />We may partner with a third party to either display advertising on our Website or to manage our advertising on other sites. Our third-party partner may use technologies such as cookies to gather information about your activities on this Website and other sites in order to provide you advertising based upon your browsing activities and interests. If you wish to not have this information used to serve you interest-based ads, you may opt-out by contacting us. Please note this does not opt you out of being served ads. You will continue to receive generic ads.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="You_block_cookies_by_activating_the_setting_on_your_browser_that_allows_you_to_refuse_the_setting_of_all_or_some_cookies_However_if_you_use_your_browser_settings_to_block_all_cookies_including_strictly_necessary_cookies_you_may_not_be_able_to_access_all_or_parts_of_our_site"
            class="ez-toc-section _mPS2id-t"
          />You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including strictly necessary cookies) you may not be able to access all or parts of our site.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="i"
            class="ez-toc-section _mPS2id-t"
          />&nbsp;<span class="ez-toc-section-end" /></h1>
          <h1><span
            id="LINKS_TO_THIRD_PARTY_SITES"
            class="ez-toc-section _mPS2id-t"
          /><b>LINKS TO THIRD PARTY SITES</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="The_Service_may_from_time_to_time_contain_links_to_and_from_third_party_websites_If_you_follow_a_link_to_any_of_these_websites_please_note_that_these_websites_have_their_own_privacy_policies_and_that_we_do_not_accept_any_responsibility_or_liability_for_their_policies_Please_check_the_individual_policies_before_you_submit_any_information_to_those_websites"
            class="ez-toc-section _mPS2id-t"
          />The Service may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those websites.<span class="ez-toc-section-end" /></h2>
          <h2><span
            id="Some_of_the_pages_on_our_Website_may_utilize_framing_techniques_to_serve_content_tofrom_our_partners_while_preserving_the_look_and_feel_of_our_Website_Please_be_aware_that_you_are_providing_your_personal_information_to_these_third_parties_and_not_us"
            class="ez-toc-section _mPS2id-t"
          />Some of the pages on our Website may utilize framing techniques to serve content to/from our partners while preserving the look and feel of our Website. Please be aware that you are providing your personal information to these third parties and not us.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="USE_OF_SERVICE_BY_MINORS"
            class="ez-toc-section _mPS2id-t"
          /><b>USE OF SERVICE BY MINORS</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="The_Service_is_not_directed_at_persons_under_16_and_we_do_not_intend_to_collect_personal_information_from_children_under_16_in_our_capacity_as_a_controller_If_you_become_aware_that_a_child_has_provided_us_with_personal_information_without_appropriate_consent_then_please_contact_us_using_the_details_below_so_that_we_can_take_the_appropriate_steps_in_accordance_with_our_legal_obligations_and_this_privacy_policy"
            class="ez-toc-section _mPS2id-t"
          />The Service is not directed at persons under 16 and we do not intend to collect personal information from children under 16 in our capacity as a controller. If you become aware that a child has provided us with personal information without appropriate consent, then please contact us using the details below so that we can take the appropriate steps in accordance with our legal obligations and this privacy policy.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="YOUR_CANADIAN_PRIVACY_RIGHTS"
            class="ez-toc-section _mPS2id-t"
          /><b>YOUR CANADIAN PRIVACY RIGHTS</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="The_Personal_Information_Protection_and_Electronic_Documents_Act_Schedule_1_Clause_49_permits_Canadian_residents_that_are_users_of_our_website_to_request_certain_information_regarding_the_existence_use_and_disclosure_of_personal_information_and_authorizes_access_to_that_information_To_make_such_a_request_please_send_an_email_to_PRIVACY_EMAIL"
            class="ez-toc-section _mPS2id-t"
          />The <i>Personal Information Protection and Electronic Documents Act</i>, Schedule 1, Clause 4.9 permits Canadian residents that are users of our website to request certain information regarding the existence, use, and disclosure of personal information and authorizes access to that information. To make such a request, please send an email to [<b>PRIVACY EMAIL</b>].<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="CHANGES_TO_THIS_POLICY"
            class="ez-toc-section _mPS2id-t"
          /><b>CHANGES TO THIS POLICY</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="We_may_update_this_privacy_policy_from_time_to_time_and_so_you_should_review_this_page_periodically_When_we_change_this_privacy_policy_in_a_material_way_we_will_update_the_last_modified_date_at_the_end_of_this_privacy_policy_Changes_to_this_privacy_policy_are_effective_when_they_are_posted_on_this_page"
            class="ez-toc-section _mPS2id-t"
          />We may update this privacy policy from time to time and so you should review this page periodically. When we change this privacy policy in a material way, we will update the “last modified” date at the end of this privacy policy. Changes to this privacy policy are effective when they are posted on this page.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="NOTICE_TO_YOU"
            class="ez-toc-section _mPS2id-t"
          /><b>NOTICE TO YOU</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="If_we_need_to_provide_you_with_information_about_something_whether_for_legal_marketing_or_other_business-related_purposes_we_will_select_what_we_believe_is_the_best_way_to_get_in_contact_with_you_We_will_usually_do_this_through_email_or_by_placing_a_notice_on_our_Website_The_fact_that_we_may_send_notices_to_you_will_not_stop_you_from_being_able_to_opt_out_of_certain_types_of_contact_as_described_in_this_privacy_policy"
            class="ez-toc-section _mPS2id-t"
          />If we need to provide you with information about something, whether for legal, marketing or other business-related purposes, we will select what we believe is the best way to get in contact with you. We will usually do this through email or by placing a notice on our Website. The fact that we may send notices to you will not stop you from being able to opt out of certain types of contact as described in this privacy policy.<span class="ez-toc-section-end" /></h2>
          <h1><span
            id="CONTACTING_US"
            class="ez-toc-section _mPS2id-t"
          /><b>CONTACTING US</b><span class="ez-toc-section-end" /></h1>
          <h2><span
            id="Regardless_of_your_location_any_questions_comments_and_requests_regarding_this_privacy_policy_are_welcome_and_should_be_addressed_to_our_Data_Protection_Officer_NAME_at_PRIVACY_EMAIL_Communication_can_also_be_addressed_to"
            class="ez-toc-section _mPS2id-t"
          />Regardless of your location, any questions, comments, and requests regarding this privacy policy are welcome and should be addressed to our Data Protection Officer, [<b>NAME</b>], at [<b>PRIVACY EMAIL</b>]. Communication can also be addressed to:<span class="ez-toc-section-end" /></h2>
          <p>[<b>NAME</b>] [<b>ADDRESS</b>]</p>
          <h2><span
            id="THIS_PRIVACY_POLICY_WAS_LAST_MODIFIED_ON_DATE"
            class="ez-toc-section _mPS2id-t"
          /><b>THIS PRIVACY POLICY WAS LAST MODIFIED ON [</b><b>DATE</b><b>]</b><span class="ez-toc-section-end" /></h2>

          <p>&nbsp;</p>

          <h2><span
            id="Who_we_are"
            class="ez-toc-section _mPS2id-t"
          />Who we are<span class="ez-toc-section-end" /></h2>

          <p>Our website address is: https://converifai.com</p>
          <h2><span
            id="Quickbooks_Data_Sharing"
            class="ez-toc-section _mPS2id-t"
          />Quickbooks Data Sharing<span class="ez-toc-section-end" /></h2>
          <p>Converifai is committed to safeguarding the personal information entrusted to us by our clients. We manage personal information in accordance with personal information protection acts, legislation, and other applicable laws. Data shared with Converifai through &nbsp; What is personal information?<br>Personal information means information about an identifiable individual. This includes an<br>individual’s name, home address and phone number, age, sex, marital or family status, an<br>identifying number, financial information, educational history, etc.<br>Include some examples of<br>personal information that<br>your organization collects.<br>What personal information do we collect?<br>We collect only the personal information that we need for the purposes of providing<br>services to our clients, including personal information needed to:<br>à open and manage an account<br>These are only examples.<br>Your policy should list the<br>purposes for which your<br>organization collects<br>personal information.<br><br>We normally collect client information directly from our clients. We may collect your<br>information from other persons with your consent or as authorized by law.<br>We inform our clients, before or at the time of collecting personal information, of the<br>purposes for which we are collecting the information. However, we don’t provide this<br>notification when a client volunteers information for an obvious purpose (for example,<br>producing a credit card for an in-store purchase when the information will be used only to<br>process the payment).</p>

          <h2><span
            id="Comments"
            class="ez-toc-section _mPS2id-t"
          />Comments<span class="ez-toc-section-end" /></h2>

          <p><strong class="privacy-policy-tutorial">Suggested text: </strong>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.</p>

          <p>An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.</p>

          <h2><span
            id="Media"
            class="ez-toc-section _mPS2id-t"
          />Media<span class="ez-toc-section-end" /></h2>

          <p><strong class="privacy-policy-tutorial">Suggested text: </strong>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.</p>

          <h2><span
            id="Cookies"
            class="ez-toc-section _mPS2id-t"
          />Cookies<span class="ez-toc-section-end" /></h2>

          <p><strong class="privacy-policy-tutorial">Suggested text: </strong>If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</p>

          <p>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>

          <p>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>

          <p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>

          <h2><span
            id="Embedded_content_from_other_websites"
            class="ez-toc-section _mPS2id-t"
          />Embedded content from other websites<span class="ez-toc-section-end" /></h2>

          <p><strong class="privacy-policy-tutorial">Suggested text: </strong>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>

          <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>

          <h2><span
            id="Who_we_share_your_data_with"
            class="ez-toc-section _mPS2id-t"
          />Who we share your data with<span class="ez-toc-section-end" /></h2>

          <p><strong class="privacy-policy-tutorial">Suggested text: </strong>If you request a password reset, your IP address will be included in the reset email.</p>

          <h2><span
            id="How_long_we_retain_your_data"
            class="ez-toc-section _mPS2id-t"
          />How long we retain your data<span class="ez-toc-section-end" /></h2>

          <p><strong class="privacy-policy-tutorial">Suggested text: </strong>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.</p>

          <p>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</p>

          <h2><span
            id="What_rights_you_have_over_your_data"
            class="ez-toc-section _mPS2id-t"
          />What rights you have over your data<span class="ez-toc-section-end" /></h2>

          <p><strong class="privacy-policy-tutorial">Suggested text: </strong>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>

          <h2><span
            id="Where_your_data_is_sent"
            class="ez-toc-section _mPS2id-t"
          />Where your data is sent<span class="ez-toc-section-end" /></h2>

          <p><strong class="privacy-policy-tutorial">Suggested text: </strong>Visitor comments may be checked through an automated spam detection service.</p>
        </div>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
table { border: 1px solid; }
td { padding: 1rem; }
</style>
